<script lang="ts" setup>
import {useSession} from '../core/session';
import Navbar from './layout/Navbar.vue';

useSession();
// window.addEventListener('visibilitychange', () => {
//     if (document.visibilityState === 'visible') {
//         location.reload();
//     }
// });
</script>
<template>
    <Navbar></Navbar>
    <div class="justify-start px-3">
        <!-- <Sections /> -->
        <div class="flex flex-col w-full">
            <router-view></router-view>
        </div>
    </div>
</template>
<style lang="css">
.fui-editor {
    display: grid;
    margin: 0 auto;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.fui-editor__left {
    grid-area: 1 / 1 / 5 / 2;
}

.fui-editor__top {
    grid-area: 1 / 2 / 4;
}

.fui-editor__main {
    min-width: var(--main-width);
    grid-area: 2 / 2 / 3 / 3;
    min-height: 320px;
    padding-right: 8px;
}

.fui-editor__main-right {
    grid-area: 1 / 3 / 3 / 4;
}

.fui-editor__bottom {
    grid-area: 3 / 2 / 4 / 3;
    max-width: var(--main-width);
}

.fui-editor__bottom-right {
    grid-area: 3 / 3 / 4 / 4;
}

.fui-editor__canvas {
    max-height: 75vh;
    flex-shrink: 0;
    overflow: auto;
    display: flex;
    padding: 10px 20px 20px 20px;
    margin: 0 auto;
}

body {
    visibility: visible !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* -webkit-appearance: none; */
    /* margin: 0; */
}

/* Firefox */
input[type='number'] {
    /* -moz-appearance: textfield; */
}
</style>
