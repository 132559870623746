import {supabase, supabaseUrl} from '/src/api/services/supabase';
import {generateUID} from '/src/utils';

export function getBucketFileName(name) {
    return `${supabaseUrl}/storage/v1/object/public/assets/${name}`;
}

export async function uploadAsset(file, projectId) {
    const UID = generateUID();
    const fileName = `${UID}_${file.name}`;
    const {data, error: uploadError} = await supabase.storage.from('assets').upload(fileName, file);
    if (uploadError) {
        console.log('Error uploading file', uploadError);
        return null;
    }

    const {data: assetRecord, error: insertError} = await supabase
        .from('assets')
        .insert({
            url: getBucketFileName(data.path),
            filename: file.name,
            project_id: projectId,
        })
        .select()
        .single();

    if (insertError) {
        console.log('Error creating asset record:', insertError);
        return null;
    }

    return assetRecord;
}

export async function removeAsset(assetId) {
    const {error} = await supabase.from('assets').delete().eq('id', assetId);

    if (error) {
        console.log('Error removing asset:', error);
        return false;
    }

    return true;
}

export async function getAssetsByProjectId(projectId) {
    const {data, error} = await supabase.from('assets').select('*').eq('project_id', projectId);

    if (error) {
        console.log('Error fetching assets:', error);
        return null;
    }

    return data;
}
