<script lang="ts" setup>
import router from '/src/router';
import {useSession} from '../../core/session';
import {signInWithEmail, signInWithGithub, signInWithGoogle, signOut} from '../../api/services/supabase';
import Icon from '/src/components/layout/Icon.vue';
import {computed, ref} from 'vue';

const session = useSession();

const email = ref('');
const errorMessage = ref('');
const isLoading = ref(false);
const isMagicLinkSent = ref(false);
const isMagicLinkError = ref(false);

async function logOut() {
    await signOut();
    session.resetAuth();
    router.push('/');
    window.location.href = '/';
}

async function sendMagicLink() {
    isLoading.value = true;
    const {result, error} = await signInWithEmail(email.value);
    isLoading.value = false;
    if (error) {
        isMagicLinkError.value = true;
        errorMessage.value = error.message;
    } else {
        isMagicLinkSent.value = true;
    }
}
</script>
<template>
    <div class="flex flex-col gap-4 mx-auto my-2 content-center max-w-sm">
        <div class="text-lg font-bold">Sign In</div>
        <template v-if="!session.state.auth">
            <div
                v-if="isMagicLinkError"
                class="alert alert-error block"
            >
                <p>Something went wrong. Please try again.</p>
                <p class="text-sm mt-2">
                    {{ errorMessage }}
                    <br />
                    <br />
                    If you cannot solve the problem, contact our support team
                    <a
                        class="link"
                        href="mailto:support@lopaka.app"
                    >
                        support@lopaka.app
                    </a>
                </p>
            </div>
            <div
                v-if="isMagicLinkSent"
                class="alert alert-info"
            >
                We've sent you a magic link to Sign In.
                <br />
                Please check your email {{ email }}
            </div>
            <div v-if="!isMagicLinkSent && !isMagicLinkError">
                <p class="mb-2">Please enter your email to continue</p>
                <form
                    class="flex join"
                    @submit.prevent="sendMagicLink"
                >
                    <input
                        v-model="email"
                        type="email"
                        placeholder="Enter your email"
                        class="input input-bordered input-primary w-full join-item"
                        required
                    />
                    <button
                        class="btn btn-primary join-item"
                        :ddisabled="isLoading"
                    >
                        Send Magic Link
                        <span
                            class="loading loading-2xs"
                            v-if="isLoading"
                        ></span>
                    </button>
                </form>
            </div>
            <div class="divider">OR</div>
            <!-- <p>OR choose your OAuth provider:</p> -->
            <button
                class="btn btn-outline"
                @click="signInWithGithub()"
            >
                <Icon type="github" />
                Continue with GitHub
            </button>
            <button
                class="btn btn-outline"
                @click="signInWithGoogle()"
            >
                <Icon type="google" />
                Continue with Google
            </button>
        </template>
        <button
            class="btn btn-outline"
            @click="logOut"
            v-else
        >
            Sign Out
        </button>
        <div class="text">
            By continuing, you agree to Lopaka's
            <a
                href="/docs/tos.md"
                class="link-primary"
            >
                Terms of Service
            </a>
            and
            <a
                href="/docs/privacy.md"
                class="link-primary"
            >
                Privacy Policy
            </a>
        </div>
    </div>
</template>
<style lang="css"></style>
