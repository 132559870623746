import {AbstractLayer} from '../../core/layers/abstract.layer';
import {TextLayer} from '../../core/layers/text.layer';
import {Point} from '../../core/point';
import {getFont} from '../../draw/fonts';
import {AbstractTool} from './abstract.tool';

export class TextTool extends AbstractTool {
    name = 'string';
    createLayer(): AbstractLayer {
        const {session} = this.editor;

        const fonts = session.platforms[session.state.platform].getFonts();

        if (session.editor.lastFontName) {
            const lastFont = fonts.find((font) => font.name === session.editor.lastFontName);
            if (lastFont) {
                this.editor.font = getFont(session.editor.lastFontName);
            }
        }
        if (!this.editor.font) {
            this.editor.font = getFont(session.platforms[session.state.platform].getFonts()[0].name);
        }
        return new TextLayer(session.getPlatformFeatures(), this.editor.font);
    }
    onStopEdit(layer: TextLayer, position: Point, originalEvent: MouseEvent): void {
        super.onStopEdit(layer, position, originalEvent);
        this.editor.font = layer.font;
    }
}
