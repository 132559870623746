each decl in declarations 
    if decl.type == 'bitmap'
        | static const unsigned char PROGMEM !{decl.data.name}[] = {!{decl.data.value}};
        |
    else 
        | !{decl}
        |
if settings.wrap
    | void draw(void) {
    |
- var textColor = defaultColor;
- var textSize = '1'
- var textWrap = true
- var pad = settings.wrap ? '    ' : ''
- var font = 'adafruit'
each layer in layers 
    case layer.type
        when 'dot'
            | @!{layer.uid};!{pad}display.drawPixel(!{layer.position[0]}, !{layer.position[1]}, !{packColor(layer.color)});
        when 'line'
            | @!{layer.uid};!{pad}display.drawLine(!{layer.p1[0]}, !{layer.p1[1]}, !{layer.p2[0]}, !{layer.p2[1]}, !{packColor(layer.color)});
        when 'rect'
            - var func = (layer.fill ? 'fill' : 'draw') + (layer.radius? 'Round' : '') + 'Rect'
            | @!{layer.uid};!{pad}display.!{func}(!{layer.position[0]}, !{layer.position[1]}, !{layer.size[0]}, !{layer.size[1]}!{layer.radius? `, ${layer.radius}`: ''}, !{packColor(layer.color)});
        when 'circle'
            - var func = layer.fill ? 'fillCircle' : 'drawCircle'
            | @!{layer.uid};!{pad}display.!{func}(!{layer.position[0] + layer.radius}, !{layer.position[1] + layer.radius}, !{layer.radius}, !{packColor(layer.color)});
        when 'string'
            if layer.color != textColor
                - textColor = layer.color
                | !{pad}display.setTextColor(!{packColor(layer.color)});
                |
            if layer.scaleFactor != textSize
                - textSize = layer.scaleFactor
                | !{pad}display.setTextSize(!{layer.scaleFactor});
                |
            if layer.wrap != textWrap
                - textWrap = layer.wrap
                | !{pad}display.setTextWrap(false);
                |
            //-     |
            if layer.font != font 
                - font = layer.font
                if font == 'adafruit'
                    | !{pad}display.setFont();
                    |
                else
                    | !{pad}display.setFont(&!{layer.font});
                    |
            | !{pad}display.setCursor(!{getTextPosition(layer).join(', ')});
            | @!{layer.uid};!{pad}display.print("!{layer.text}");
        when 'paint'
        when 'icon'
            | @!{layer.uid};!{pad}display.drawBitmap(!{layer.position[0]}, !{layer.position[1]}, !{layer.imageName}, !{layer.size[0]}, !{layer.size[1]}, !{packColor(layer.color)});
        default 
            |
            | // Unknown layer type !{layer.type}
            |
    |
    |
if settings.wrap
    | }
    |