<script lang="ts" setup>
import {computed, onMounted, onUnmounted, ref, toRefs} from 'vue';
import {useSession} from '/src/core/session';
import {createProjectWithScreen, deleteProject, Project, updateProject} from '/src/api/projects';
import FuiButton from '../fui/FuiButton.vue';
import Icon from '/src/components/layout/Icon.vue';
import router from '/src/router';
import ProjectWizardSandbox from './ProjectWizardSandbox.vue';
import {logEvent} from '/src/utils';

const props = defineProps<{
    project?: Project;
    sandboxProjects?: Project[];
}>();

const session = useSession();
const {platforms, state, getDisplays} = session;
const {subscription} = toRefs(state);

const projectTitle = ref('New Project');
const projectPlatform = ref('u8g2');
const projectDisplayId = ref(22);
const projectScreen = ref([]);
const isCustomDisplay = ref(false);
const customWidth = ref();
const customHeight = ref();

const isSubscriptionActive = computed(() => !!subscription.value && subscription.value?.status !== 'expired');
const classList = computed(() => ({
    'max-w-sm': !props.sandboxProjects,
    'max-w-xl': !!props.sandboxProjects,
}));
const displays = computed(() => getDisplays(projectPlatform.value));
const projectPublic = ref(isSubscriptionActive.value ? false : true);

const emit = defineEmits(['close', 'update']);

const closeModal = () => {
    emit('close');
};

const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
        closeModal();
    }
    if (event.key === 'Enter') {
        event.preventDefault();
        submitProject();
    }
};

onMounted(() => {
    document.addEventListener('keydown', handleEscapeKey);
    if (props.project) {
        projectTitle.value = props.project.title;
        projectPlatform.value = props.project.platform;
        customWidth.value = props.project.screen_x;
        customHeight.value = props.project.screen_y;
        isCustomDisplay.value = true;
        projectPublic.value = !props.project.private;
    }
});

onUnmounted(() => {
    document.removeEventListener('keydown', handleEscapeKey);
});

function updateProjectProps(sandboxProject) {
    projectTitle.value = sandboxProject.title;
    projectPlatform.value = sandboxProject.platform;
    customWidth.value = sandboxProject.screen_x;
    customHeight.value = sandboxProject.screen_y;
    isCustomDisplay.value = true;
    projectScreen.value = sandboxProject.screen.layers;
}

async function submitProject() {
    const width = isCustomDisplay.value ? customWidth.value : displays.value[projectDisplayId.value].size.x;
    const height = isCustomDisplay.value ? customHeight.value : displays.value[projectDisplayId.value].size.y;

    const projectProps = {
        title: projectTitle.value,
        platform: projectPlatform.value,
        screen_x: width,
        screen_y: height,
        private: !projectPublic.value,
    };

    try {
        if (props.project) {
            await updateProject({
                id: props.project.id,
                ...projectProps,
            });
            logEvent('button_project_save');
        } else {
            const data = await createProjectWithScreen(projectProps, `Screen 1`, projectScreen.value);
            router.push(`/editor/${data.id}/${data.screens[0].id}`);
            logEvent('button_project_add');
        }
        emit('update');
        closeModal();
    } catch (error) {
        // TODO: Add error handling
        console.error('Error creating project:', error);
    }
}

async function removeProject() {
    await deleteProject(props.project.id);
    emit('update');
    closeModal();
    logEvent('button_project_remove');
}
</script>
<template>
    <div class="modal modal-open">
        <div
            class="modal-box border border-base-300 overflow-visible -mt-64"
            :class="classList"
        >
            <h3
                class="font-bold text-lg flex flex-row justify-between"
                v-if="project"
            >
                Edit Project
                <FuiButton
                    danger
                    @click="removeProject"
                    title="Remove this Project with it's Screens permanently. Forever. Be careful."
                >
                    <Icon
                        type="trash"
                        danger
                    />
                </FuiButton>
            </h3>
            <h3
                class="font-bold text-lg"
                v-else-if="sandboxProjects"
            >
                Import Project
            </h3>
            <h3
                class="font-bold text-lg"
                v-else
            >
                Create New Project
            </h3>
            <form @submit.prevent="submitProject">
                <div class="flex flex-row gap-8">
                    <div
                        class="flex-grow w-1/3 mt-2"
                        v-if="sandboxProjects"
                    >
                        <ProjectWizardSandbox
                            :sandboxProjects="sandboxProjects"
                            @updateProjectProps="updateProjectProps"
                        />
                    </div>
                    <div class="flex-grow">
                        <div class="form-control">
                            <label class="label">
                                <span class="label-text">Title</span>
                            </label>
                            <input
                                v-model="projectTitle"
                                type="text"
                                placeholder="Enter project title"
                                class="input input-sm input-bordered"
                                required
                            />
                        </div>
                        <div class="form-control">
                            <label class="label">
                                <span class="label-text">Platform</span>
                            </label>
                            <select
                                v-model="projectPlatform"
                                class="select select-sm select-bordered"
                                required
                                :disabled="!!project || !!sandboxProjects"
                            >
                                <option
                                    disabled
                                    value=""
                                >
                                    Select a platform
                                </option>
                                <option
                                    v-for="(p, idx) in platforms"
                                    :key="idx"
                                    :value="idx"
                                >
                                    {{ p.getName() }}
                                </option>
                            </select>
                        </div>
                        <div class="form-control">
                            <div class="flex flex-row gap-2">
                                <label class="label">
                                    <span class="label-text">Screen Size</span>
                                </label>
                                <div
                                    class="form-control"
                                    v-if="!project && !sandboxProjects"
                                >
                                    <label class="label cursor-pointer">
                                        <input
                                            type="checkbox"
                                            class="toggle toggle-sm toggle-primary mr-2"
                                            v-model="isCustomDisplay"
                                        />
                                        <span class="label-text">Custom</span>
                                    </label>
                                </div>
                            </div>
                            <fieldset
                                class="flex flex-row gap-2"
                                v-if="isCustomDisplay"
                                :disabled="!!sandboxProjects"
                            >
                                <input
                                    v-model="customWidth"
                                    type="number"
                                    placeholder="Width"
                                    class="input input-sm input-bordered w-1/2"
                                    required
                                    min="1"
                                    max="10000"
                                    step="1"
                                />
                                <input
                                    v-model="customHeight"
                                    type="number"
                                    placeholder="Height"
                                    class="input input-sm input-bordered w-1/2"
                                    required
                                    min="1"
                                    max="10000"
                                    step="1"
                                />
                            </fieldset>

                            <select
                                v-if="!isCustomDisplay"
                                v-model="projectDisplayId"
                                class="select select-sm select-bordered"
                                required
                                :disabled="!!sandboxProjects"
                            >
                                <option
                                    disabled
                                    value=""
                                >
                                    Select screen size
                                </option>
                                <option
                                    v-for="(item, idx) in displays"
                                    :key="idx"
                                    :value="idx"
                                >
                                    {{ item.title }}
                                </option>
                            </select>
                        </div>
                        <div class="form-control flex flex-row mt-2">
                            <label class="label cursor-pointer justify-start relative">
                                <input
                                    :disabled="!isSubscriptionActive && projectPublic"
                                    class="checkbox checkbox-sm checkbox-primary"
                                    type="checkbox"
                                    v-model="projectPublic"
                                />
                                <div
                                    class="tooltip tooltip-bottom"
                                    data-tip="Public projects are visible to all users"
                                >
                                    <div class="label-text ml-2">Is Public (read only)</div>
                                </div>
                                <div
                                    v-if="!isSubscriptionActive"
                                    class="bg-success text-black badge ml-2"
                                >
                                    <div
                                        class="tooltip tooltip-bottom"
                                        data-tip="Unlimited projects and privacy"
                                    >
                                        <router-link
                                            to="/price"
                                            class="font-bold hover:text-secondary"
                                            @click="logEvent('button_pro', 'wizardPublicGetPro')"
                                        >
                                            Get Plus
                                        </router-link>
                                    </div>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="modal-action flex justify-between">
                    <FuiButton
                        @click="closeModal"
                        type="button"
                    >
                        Cancel
                    </FuiButton>
                    <FuiButton
                        filled
                        type="submit"
                    >
                        Submit
                    </FuiButton>
                </div>
            </form>
        </div>
    </div>
</template>
