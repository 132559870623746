<script lang="ts" setup>
import {computed, onMounted, ref, toRefs} from 'vue';
import FuiButton from '/src/components/fui/FuiButton.vue';
import ScreensList from '/src/components/screens/ScreensList.vue';
import ModalPricing from '/src/components/layout/ModalPricing.vue';
import ProjectWizard from '/src/components/projects/ProjectWizard.vue';
import ProjectTitle from '/src/components/projects/ProjectTitle.vue';
import {logEvent} from '/src/utils';
import {loadProject, useSession} from '/src/core/session';
import {getProjectsWithScreens, Project} from '/src/api/projects';
import {createScreen} from '/src/api/screens';
import platforms from '/src/core/platforms';
import router from '/src/router';

const session = useSession();
const {state} = session;
const {auth, subscription} = toRefs(state);

const isPricesVisible = ref(false);
const isWizardVisible = ref(false);
const isProjectsLoaded = ref(false);
const sandboxProjects = ref([]);
const projects = ref([]);

const isSubscriptionActive = computed(() => !!subscription.value && subscription.value?.status !== 'expired');
const isNewProjects = computed(() => isSubscriptionActive.value || projects.value.length < 3);

function projectWizard() {
    logEvent('button_project_new');
    if (auth.value && isNewProjects.value) {
        isWizardVisible.value = true;
    } else {
        showPrices('projectWizard');
    }
}

async function sandboxWizard() {
    const displayStored = localStorage.getItem('lopaka_display');
    let displayArr = [];
    if (displayStored) {
        displayArr = displayStored.split('×').map((n) => parseInt(n));
    }
    for (let platform in platforms) {
        const layers = JSON.parse(localStorage.getItem(`${platform}_lopaka_layers`));
        if (layers && layers.length > 0) {
            sandboxProjects.value.push({
                id: 0,
                title: `Imported project`,
                platform: platform,
                screen: {
                    id: 0,
                    layers,
                },
                screen_x: displayArr[0],
                screen_y: displayArr[1],
            });
        }
    }
    if (sandboxProjects.value.length > 0) {
        await loadProject(sandboxProjects.value[0], sandboxProjects.value[0].screen);
        isWizardVisible.value = true;
    }
}

function showPrices(meta) {
    isPricesVisible.value = true;
    logEvent('button_pro', meta);
}

function closeModal() {
    isPricesVisible.value = false;
    isWizardVisible.value = false;
    sandboxProjects.value = [];
}

function updateProjects() {
    getProjectsWithScreens().then((result) => {
        projects.value = result;
        isProjectsLoaded.value = true;
    });
}

onMounted(() => {
    updateProjects();
});
</script>
<template>
    <div class="flex flex-row gap-4 my-2 content-center">
        <div class="text-lg font-bold">My Projects</div>

        <FuiButton
            @click="projectWizard"
            filled
        >
            + New Project
            <div
                v-if="!isNewProjects"
                class="bg-success text-secondary text-xs capitalize px-1 rounded-full absolute -top-2 -right-4"
            >
                Plus
            </div>
        </FuiButton>
        <!-- <div
            v-if="auth && isProjectsLoaded && !isSubscriptionActive"
            class="btn btn-success btn-outline btn-sm uppercase ml-auto"
            @click="showPrices('Upgrade to Plus')"
        >
            Upgrade to Plus
        </div> -->
    </div>
    <div v-if="auth && isProjectsLoaded">
        <div
            v-for="project in projects"
            :key="project.id"
            class="pb-2"
        >
            <ProjectTitle
                :project="project"
                @update="updateProjects"
            />
            <ScreensList
                flow="row"
                @createScreen="createScreen"
                :project="project"
                :platform="project.platform"
                @showModalPricing="showPrices('createScreen')"
            />
        </div>
        <div
            class="pt-32 w-full text-center"
            v-if="isProjectsLoaded && projects.length < 3"
        >
            <p v-if="projects.length === 0">
                Start with creating a
                <span
                    class="link link-primary"
                    @click="projectWizard"
                >
                    New project
                </span>
            </p>

            <p v-if="projects.length < 3">
                If you had a Project in your Local Sandbox then you can
                <span
                    class="link link-primary"
                    @click="sandboxWizard"
                >
                    Import it
                </span>
            </p>
        </div>
    </div>
    <div
        v-else
        class="mx-auto py-48"
    >
        <span class="loading loading-spinner text-primary loading-lg"></span>
    </div>

    <ModalPricing
        v-if="isPricesVisible"
        @close="closeModal"
        canBeClosed
    />
    <ProjectWizard
        v-if="isWizardVisible"
        :sandboxProjects="sandboxProjects.length ? sandboxProjects : null"
        @close="closeModal"
        @update="updateProjects"
    />
</template>
<style lang="css"></style>
