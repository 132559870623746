<script lang="ts" setup>
import {computed, onMounted, onUnmounted, ref, toRefs, watch} from 'vue';
import {loadLayers, loadProject, useSession} from '/src/core/session';
import {createProjectWithScreen, deleteProject, Project, updateProject} from '/src/api/projects';
import FuiButton from '../fui/FuiButton.vue';
import Icon from '/src/components/layout/Icon.vue';
import router from '/src/router';

const props = defineProps<{
    sandboxProjects: Project[];
}>();

const emit = defineEmits(['updateProjectProps']);

const session = useSession();

const screenPreview = ref();
const selectedProject = ref(props.sandboxProjects[0]);
const selectedPlatform = ref(props.sandboxProjects[0].platform);

watch(selectedPlatform, async (newPlatform) => {
    selectedProject.value = props.sandboxProjects.find((project) => project.platform === newPlatform);
    await loadProject(selectedProject.value, (selectedProject.value as any).screen);
    emit('updateProjectProps', selectedProject.value);
});

onMounted(async () => {
    session.virtualScreen.setCanvas(screenPreview.value, false);
    emit('updateProjectProps', selectedProject.value);
});
</script>
<template>
    <div class="flex mb-2">
        <canvas
            class="screen max-w-fit max-h-[96px] border border-secondary"
            ref="screenPreview"
            :width="selectedProject.screen_x"
            :height="selectedProject.screen_y"
            :style="{
                backgroundColor: session.getPlatformFeatures().screenBgColor,
            }"
        />
    </div>
    <label
        class="label cursor-pointer justify-start gap-2"
        v-for="project in sandboxProjects"
    >
        <input
            type="radio"
            name="project"
            class="radio radio-xs"
            :value="project.platform"
            v-model="selectedPlatform"
        />
        <span class="label-text">{{ project.platform }}</span>
    </label>
</template>
<style scoped>
.screen {
    /* cursor: crosshair; */
    image-rendering: pixelated;
    background: var(--primary-color);
    text-rendering: geometricPrecision;
    font-smooth: never;
    -webkit-font-smoothing: none;
    opacity: 0.9;
}
</style>
