import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router';
import Assets from './components/layout/Assets.vue';
import Prototype from './components/layout/Prototype.vue';
import Login from './components/layout/Login.vue';
import Gallery from '/src/components/gallery/Gallery.vue';
import EditorView from '/src/components/layout/EditorView.vue';
import Projects from '/src/components/projects/Projects.vue';
import Pricing from '/src/components/pricing/Pricing.vue';
import GalleryEditor from '/src/components/gallery/GalleryEditor.vue';
import {nextTick} from 'vue';

const routes = [
    {path: '/assets', component: Assets},
    {path: '/prototype', component: Prototype},
    {path: '/gallery', component: Gallery},
    {path: '/gallery/:project_id/:screen_id', component: GalleryEditor},
    {path: '/price', component: Pricing},
    {path: '/login', component: Login},
    {
        path: '/',
        component: Projects,
    },
    {
        path: '/editor/:project_id/:screen_id',
        alias: '/editor/:project_id',
        component: EditorView,
    },
    {
        path: '/sandbox',
        component: EditorView,
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/',
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes as RouteRecordRaw[],
});

router.afterEach((to, from, failure) => {
    if (!failure) {
        nextTick(() => {
            if (window.posthog) window.posthog.capture('$pageview', {path: to.fullPath});
        });
    }
});

export default router;
