import {supabase} from '/src/api/services/supabase';
export interface Project {
    id: number;
    title: string;
    screens?: ProjectScreen[];
    platform: string;
    user_id?: string;
    screen_x: number;
    screen_y: number;
    private?: boolean;
}
export interface GalleryProject extends Project {
    screens_count: number;
}
export interface ProjectScreen {
    id: number;
    private?: boolean;
    project_id?: number;
    title?: string;
    user_id?: string;
    img_preview?: string;
    layers?: any[];
}

export async function getProjectsWithScreens(): Promise<Project[]> {
    const {data, error} = await supabase.from('projects_with_screens').select('*');

    if (error) {
        console.error('Error fetching projects:', error);
        return;
    }

    return data;
}

export async function getProjectWithScreens(projectId: string): Promise<Project | null> {
    const {data, error} = await supabase.rpc('get_project_with_screens', {project_id: projectId});

    if (error) {
        console.error('Error fetching project:', error);
        return null;
    }

    console.log('Project:', data);
    return data;
}

export async function createProjectWithScreen(project, screenTitle, screenLayers): Promise<Project> {
    const {data, error} = await supabase.rpc('create_project_with_screen', {
        project_title: project.title,
        project_platform: project.platform,
        project_screen_w: project.screen_x,
        project_screen_h: project.screen_y,
        screen_title: screenTitle,
        screen_layers: screenLayers,
    });

    if (error) {
        console.error('Error creating project with screen:', error);
        return null;
    }

    return data;
}

export async function updateProject(project: Project): Promise<void> {
    const {error} = await supabase.from('projects').update(project).eq('id', project.id);

    if (error) {
        console.error('Error updating project:', error);
        return;
    }
}

export async function deleteProject(projectId: number): Promise<void> {
    const {error} = await supabase.rpc('delete_project_with_screens', {
        project_id_to_delete: projectId,
    });
    if (error) {
        console.error('Error deleting project with screens:', error);
        return;
    }
}

export async function forkProject(projectId: number): Promise<{data: any; error: any}> {
    const {data, error} = await supabase.rpc('fork_project', {
        original_project_id: projectId,
    });
    return {data, error};
}

export async function getPublicProjectsWithScreens(from = 0, to = 50): Promise<Project[]> {
    const {data, error} = await supabase.from('public_projects_with_screens').select('*').range(from, to);

    if (error) {
        console.error('Error fetching projects:', error);
        return;
    }

    return data;
}

export async function getGalleryEditorsChoice(from = 0, to = 50): Promise<Project[]> {
    const {data, error} = await supabase.from('gallery_projects_editors').select('*').range(from, to);

    if (error) {
        console.error('Error fetching projects:', error);
        return;
    }
    return data;
}

export async function getGalleryTrending(from = 0, to = 50): Promise<Project[]> {
    const {data, error} = await supabase.from('gallery_projects_trends').select('*').range(from, to);

    if (error) {
        console.error('Error fetching projects:', error);
        return;
    }
    return data;
}
