import {createClient, SupabaseClient} from '@supabase/supabase-js';
import router from '/src/router';
import {generateUID} from '/src/utils';

export const supabaseUrl = 'https://gyzuqeguaxgkzpavcejr.supabase.co';
const supabaseKey =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imd5enVxZWd1YXhna3pwYXZjZWpyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTk3NDEwMTAsImV4cCI6MjAzNTMxNzAxMH0.8ONWzl8smPflFv2eZ2QugeCEgMqQL9V33fHOAgcrz90';

export const supabase: SupabaseClient = createClient(supabaseUrl, supabaseKey);

supabase.auth.onAuthStateChange((event, session) => {
    setTimeout(async () => {
        if (event === 'SIGNED_IN' && router.currentRoute.value.path === '/login') {
            window.location.href = '/';
        }
    }, 0);
});

export async function signInWithGithub() {
    const {data, error} = await supabase.auth.signInWithOAuth({
        provider: 'github',
        options: {
            redirectTo: import.meta.env.VITE_APP_URL,
        },
    });
}

export async function signOut() {
    const {error} = await supabase.auth.signOut();
}

export async function getSession() {
    const {
        data: {session},
    } = await supabase.auth.getSession();
    return session;
}

export async function signInWithGoogle() {
    const {data, error} = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
            redirectTo: import.meta.env.VITE_APP_URL,
        },
    });
}

export async function signInWithEmail(email) {
    const {data, error} = await supabase.auth.signInWithOtp({
        email: email,
    });
    return {
        result: data,
        error,
    };
}
